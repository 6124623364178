var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('validation-observer',{ref:"rules",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-card',{attrs:{"no-body":""}},[_c('b-row',[_c('b-col',{attrs:{"lg":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Customer'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":_vm.$t('Customer'),"label-for":""}},[_c('v-select',{attrs:{"id":"customer","options":_vm.customerList,"label":"info","placeholder":"Select Customer","state":errors[0] ? false : valid ? true : null},on:{"input":function($event){return _vm.getPyUsage(_vm.company)}},model:{value:(_vm.company),callback:function ($$v) {_vm.company=$$v},expression:"company"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"lg":"12"}},[_c('div',{staticClass:"ll-title"},[_vm._v(_vm._s(_vm.$t('Py-Waybill Usage')))]),_c('el-table',{attrs:{"row-style":{ height: '20px' },"cell-style":{ fontSize: '14px', padding: '3px 0' },"data":_vm.items,"border":"","empty-text":_vm.emptyText}},[_c('el-table-column',{attrs:{"prop":"pyCreated","fixed":"left","label":"Created"}}),_c('el-table-column',{attrs:{"prop":"pyUsed","fixed":"left","label":_vm.$t('Used')}}),_c('el-table-column',{attrs:{"prop":"pyRemaining","fixed":"left","label":_vm.$t('Remaining')}})],1)],1)],1),_c('b-row',{staticStyle:{"margin-top":"20px"}},[_c('b-col',{attrs:{"lg":"12"}},[_c('div',{staticClass:"ll-title"},[_vm._v(_vm._s(_vm.$t('Py-Waybill Information')))]),_c('validation-provider',{attrs:{"name":_vm.$t('Account Number'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":_vm.$t('Account Number'),"label-for":""}},[_c('b-form-input',{ref:"input1",attrs:{"size":"ll","state":errors[0] ? false : valid ? true : null},model:{value:(_vm.scaninfo.accountNumber),callback:function ($$v) {_vm.$set(_vm.scaninfo, "accountNumber", $$v)},expression:"scaninfo.accountNumber"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"lg":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Company Name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":_vm.$t('Company Name'),"label-for":""}},[_c('b-form-input',{ref:"input",attrs:{"size":"ll","state":errors[0] ? false : valid ? true : null},model:{value:(_vm.scaninfo.companyName),callback:function ($$v) {_vm.$set(_vm.scaninfo, "companyName", $$v)},expression:"scaninfo.companyName"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"lg":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Address'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":_vm.$t('Address'),"label-for":""}},[_c('b-form-input',{ref:"input",attrs:{"size":"ll","state":errors[0] ? false : valid ? true : null},model:{value:(_vm.scaninfo.address),callback:function ($$v) {_vm.$set(_vm.scaninfo, "address", $$v)},expression:"scaninfo.address"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"lg":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Contact Person'),"label-for":""}},[_c('b-form-input',{ref:"input",attrs:{"size":"ll"},model:{value:(_vm.scaninfo.contactPerson),callback:function ($$v) {_vm.$set(_vm.scaninfo, "contactPerson", $$v)},expression:"scaninfo.contactPerson"}})],1)],1),_c('b-col',{attrs:{"lg":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Contact Phone'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":_vm.$t('Contact Phone'),"label-for":""}},[_c('b-form-input',{ref:"input",attrs:{"size":"ll","state":errors[0] ? false : valid ? true : null},model:{value:(_vm.scaninfo.phone_no),callback:function ($$v) {_vm.$set(_vm.scaninfo, "phone_no", $$v)},expression:"scaninfo.phone_no"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"lg":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Sort code'),"label-for":""}},[_c('b-form-input',{ref:"input",attrs:{"size":"ll"},model:{value:(_vm.scaninfo.sortcode),callback:function ($$v) {_vm.$set(_vm.scaninfo, "sortcode", $$v)},expression:"scaninfo.sortcode"}})],1)],1)],1)],1),_c('div',[_c('div',{staticClass:"w-100 d-flex justify-content-end"},[_c('b-button',{staticClass:"btn mr-2",attrs:{"variant":"info","disabled":invalid},on:{"click":_vm.download}},[_vm._v(" "+_vm._s(_vm.$t('Download Pdf'))+" ")]),_c('b-button',{staticClass:"btn",attrs:{"variant":"primary","disabled":invalid},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")])],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }