<template>
  <section>
    <validation-observer ref="rules" v-slot="{ invalid }">
      <b-card no-body>
          <b-row >
              <b-col lg="12" >
                <validation-provider :name="$t('Customer')" rules="required">
                  <b-form-group
                  :label="$t('Customer')"
                  label-for=""
                  slot-scope="{ valid, errors }"
                  >
                  <v-select
                      id="customer"
                      :options="customerList"
                      v-model="company"
                      @input="getPyUsage(company)"
                      label="info"
                      placeholder="Select Customer"
                      :state="errors[0] ? false : valid ? true : null"
                  >
                    </v-select>
                    <b-form-invalid-feedback>
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                  </validation-provider>
              </b-col>
          </b-row>
          <b-row>
              <b-col lg="12" class="">
                  <div class="ll-title">{{ $t('Py-Waybill Usage') }}</div>
                  <el-table
                      :row-style="{ height: '20px' }"
                      :cell-style="{ fontSize: '14px', padding: '3px 0' }"
                      :data="items"
                      border
                      :empty-text="emptyText"
                      >
                      <el-table-column
                      prop="pyCreated"
                      fixed="left"
                      label="Created"
                      >
                      </el-table-column>
                      <el-table-column
                      prop="pyUsed"
                      fixed="left"
                      :label="$t('Used')"
                      >
                      </el-table-column>
                      <el-table-column
                      prop="pyRemaining"
                      fixed="left"
                      :label="$t('Remaining')"
                      >
                      </el-table-column>
                  </el-table>
              </b-col>
          </b-row>
          <b-row style="margin-top:20px">
              <b-col lg="12">
                  <div class="ll-title">{{ $t('Py-Waybill Information') }}</div>
                  <validation-provider :name="$t('Account Number')" rules="required">
                    <b-form-group
                    :label="$t('Account Number')"
                    label-for=""
                    slot-scope="{ valid, errors }"
                    >
                      <b-form-input size="ll"
                          v-model="scaninfo.accountNumber"
                          ref="input1"
                           :state="errors[0] ? false : valid ? true : null"
                      />
                      <b-form-invalid-feedback>
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
              </b-col>
              <b-col lg="12">
                <validation-provider :name="$t('Company Name')" rules="required">
                  <b-form-group
                  :label="$t('Company Name')"
                  label-for=""
                  slot-scope="{ valid, errors }"
                  >
                    <b-form-input size="ll"
                        v-model="scaninfo.companyName"
                        ref="input"
                        :state="errors[0] ? false : valid ? true : null"
                    />
                    <b-form-invalid-feedback>
                        {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col lg="12">
                <validation-provider :name="$t('Address')" rules="required">
                  <b-form-group
                  :label="$t('Address')"
                  label-for=""
                  slot-scope="{ valid, errors }"
                  >
                    <b-form-input size="ll"
                        v-model="scaninfo.address"
                        ref="input"
                        :state="errors[0] ? false : valid ? true : null"
                    />
                    <b-form-invalid-feedback>
                        {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col lg="12">
                  <b-form-group
                  :label="$t('Contact Person')"
                  label-for=""
                  >
                  <b-form-input size="ll"
                      v-model="scaninfo.contactPerson"
                      ref="input"
                  />
                  </b-form-group>
              </b-col>
              <b-col lg="12">
                <validation-provider :name="$t('Contact Phone')" rules="required">
                  <b-form-group
                  :label="$t('Contact Phone')"
                  label-for=""
                  slot-scope="{ valid, errors }"
                  >
                  <b-form-input size="ll"
                      v-model="scaninfo.phone_no"
                      ref="input"
                      :state="errors[0] ? false : valid ? true : null"
                  />
                  <b-form-invalid-feedback>
                        {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col lg="12">
                  <b-form-group
                  :label="$t('Sort code')"
                  label-for=""
                  >
                  <b-form-input size="ll"
                      v-model="scaninfo.sortcode"
                      ref="input"
                  />
                  </b-form-group>
              </b-col>
          </b-row>
      </b-card>
      <div>
          <div class="w-100 d-flex justify-content-end">
              <b-button
                  variant="info"
                  class="btn mr-2"
                  @click="download"
                  :disabled="invalid"
              >
                  {{ $t('Download Pdf') }}
              </b-button>
              <b-button
                  variant="primary"
                  class="btn"
                  @click="save"
                  :disabled="invalid"
              >
                  {{ $t('Save') }}
              </b-button>
          </div>
      </div>
    </validation-observer>
  </section>
</template>

<script>
import {
  BFormGroup,
  BFormSelect,
  BFormInput,
  BButton,
  BCard,
  BCardBody,
  BRow,
  BCol,
  BSidebar,
  BTable,
  BInputGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
export default {
  components: {
    BFormGroup,
    BFormSelect,
    BFormInput,
    BButton,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BSidebar,
    vSelect,
    BTable,
    BInputGroup,
    ToastificationContent,
  },
  data() {
    return {
      customerList:[],
      items:[],
      scaninfo:{
        accountNumber:'',
        companyName:'',
        address:'',
      },
      company:'',
      isListenModel:false,
      emptyText:"No matching records found",
      disabled:true,
      keAccountNumber:null,
      // pdfDisabled:true
    }
  },
  computed: {
  },
  created() {
  },
  mounted() {
      this.queryCustomer()
  },
  methods: {
    queryCustomer() {
      // this.$http.get('/admin/settings/organization/getCompany').then(res => {
      //    this.customerList = res.data.data
      // })
      this.$http.get('/admin/settings/organization/findAllOrganization').then(res => {
         this.customerList = res.data.data.map(res=>{
          let arr = {id:res.id,orgCode:res.orgCode, info:res.orgCode+' - '+res.displayName+' - '+res.keAccountNumber,name:res.name,keAccountNumber:res.keAccountNumber}
          return arr
        })

      })
    },
    getPyUsage(company) {
      this.scaninfo.orgID = company.orgCode
      this.scaninfo.company = company.name
      this.keAccountNumber = company.keAccountNumber
      // this.pdfDisabled = false
      // this.disabled = false
      this.$http.get('/py/getPyUsage?orgID='+company.orgCode).then(res => {
        // this.items = res.data.data?[res.data.data]:null
        if(res.data.code == 200 && res.data.data != 'success'){
            this.items = [res.data.data]
            // this.scaninfo = res.data.data
            // this.scaninfo={
              this.scaninfo.accountNumber=res.data.data.accountNumber,
              this.scaninfo.companyName=res.data.data.companyName,
              this.scaninfo.address=res.data.data.address,
              this.scaninfo.contactPerson=res.data.data.contactPerson,
              this.scaninfo.phone_no=res.data.data.phone_no,
              this.scaninfo.sortcode=res.data.data.sortCode
            // }
        }else{
          this.items = []
          this.scaninfo={
            accountNumber:'',
            companyName:'',
            address:'',
            contactPerson:'',
            phone_no:'',
            sortcode:'',
          }
        }
      })
    },
    download(){
        let where = ""
        let params = this.scaninfo
        for(var k in params){
            where +=k+"="+params[k] + "&"
        }
        this.$http.get(`/py/DownloadLabel?${where}`).then(res => {
            let bstr = atob(res.data.data)
            let n = bstr.length
            let u8arr = new Uint8Array(n)
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n)
            }
            let blob = new Blob([u8arr],{
              type:'application/pdf;charset-UTF-8'
            })
            let url = window.URL.createObjectURL(blob)
            window.open(url,'_blank')
            let a = document.createElement('a')
            a.href = url
            a.download = 'PY-Waybill-'+this.keAccountNumber+'.pdf'
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
            window.URL.revokeObjectURL(url)
      })
    },
    save(){
      console.info(this.scaninfo,'0--')
        let where = ""
        let params = this.scaninfo
        for(var k in params){
            where +=k+"="+params[k] + "&"
        }
        this.disabled = true
        this.$http.get(`/py/save?${where}`).then(res => {
            this.disabled = false
            if (res.data.code == '200') {
            this.$toast({
                component: ToastificationContent,
                props: {
                title: res.data.message,
                icon: 'CheckIcon',
                variant: 'success',
                },
            })
           this.$emit('listenModel', this.isListenModel)
        }
      })
    },
  },
}
</script>
<style scoped>
.ll-title{
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 50px;
}
</style>
